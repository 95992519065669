import { Injectable } from '@angular/core';
import { ToastController } from "@ionic/angular";

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastCtrl: ToastController) {}

  async openToast(message: string, position: 'bottom' | 'middle' | 'top' = 'bottom'): Promise<void> {
    const toast = await this.toastCtrl.create({
      message,
      position,
      keyboardClose: true,
      duration: 7000,
      cssClass: 'clickable-toast',
    });

    toast.addEventListener('click', async () => {
      await toast.dismiss();
    });

    await toast.present();
  }
}
