import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@ionic/angular/standalone';

@Injectable({
  providedIn: 'root',
})
export class BarionPixelService {
  private scriptLoaded = false;

  constructor(
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document
  ) {}

  loadPixelScript(): void {

    if (this.platform.is('hybrid')) {
      return;
    }

    if (this.scriptLoaded) {
      console.log('Barion Pixel már betöltve.');
      return;
    }

    // Barion Pixel init
    // @ts-ignore
    window['bp'] = window['bp'] || function () {
      // @ts-ignore
      (window['bp'].q = window['bp'].q || []).push(arguments);
    };
    // @ts-ignore
    window['bp'].l = 1 * new Date();

    // Script inject
    const scriptElement = this.document.createElement('script');
    const firstScript = this.document.getElementsByTagName('script')[0];
    scriptElement.async = true;
    scriptElement.src = 'https://pixel.barion.com/bp.js';

    scriptElement.onload = () => {
      console.log('Barion Pixel script betöltve.');
      // @ts-ignore
      window['barion_pixel_id'] = environment.barionPixelId;

      // Pixel init
      // @ts-ignore
      window['bp']('init', 'addBarionPixelId', window['barion_pixel_id']);
    };

    scriptElement.onerror = () => {
      console.error('Barion Pixel betöltése sikertelen.');
    };

    firstScript.parentNode?.insertBefore(scriptElement, firstScript);

    this.scriptLoaded = true;
  }
}
