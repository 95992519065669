import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule
} from '@angular/common/http';
import {StorageService} from "src/app/core/services/storage.service";
import { HttpTokenInterceptor } from './interceptors/index';
import { ListErrorsComponent } from 'src/app/core/components/listerrors/list-errors.component';
import { ApiService } from 'src/app/core/services/api.service';
import { AuthGuard } from 'src/app/core/services/auth-guard.service';
import { JwtService } from 'src/app/core/services/jwt.service';
import { UserService } from 'src/app/core/services/user.service';
import {NoNetInterceptorService} from "src/app/core/interceptors/no-net-interceptor.service";
import {NetworkCheckService} from "src/app/core/services/network-check.service";
import {ToastService} from "src/app/core/services/toast-service.service";
import {SafePipe} from "src/app/core/pipes/htmlsanitizer.pipe";
import {ReactiveFormsModule} from "@angular/forms";

import {AuthInterceptor} from "src/app/core/interceptors/auth.interceptor";
import {FcmService} from "./services/firebase-cloud-messaging.service";
import { NotificationService } from './services/notification.service';
import {SettingsService} from "./services/settings.service";

const interceptorProviders =
  [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NoNetInterceptorService, multi: true }
  ];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    ListErrorsComponent,
    SafePipe,
  ],
  providers: [
    interceptorProviders,
    ApiService,
    AuthGuard,
    NotificationService,
    FcmService,
    StorageService,
    JwtService,
    UserService,
    ToastService,
    NetworkCheckService,
    SafePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    SettingsService
  ],
  exports: [
    ListErrorsComponent,
    SafePipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {}
